export const Enums = {
    defaultBackgroundColor: "#ffffff",
    defaultFontColor: "#000000",
    defaultFontSize: "10px",
    ChangeStatus: {
        Active: 1,
        Inactive: 2,
        Delete: 3,
        UnLockUser: 6,
        DisplayHomePageOn: 9,
        DisplayHomePageOff: 10,
        LockUser: 13
    },

    Dateformat: {
        Default: "dd-MM-yyyy"
    },

    UserType: {
        MasterAdmin: 1,
        MasterAdminUser: 2,
        EndCustomer: 3
    },
    Currency: {
        CurrencyType: "$"
    },
    DecimalSettings: {
        Default: 2
    },

    ActiveStatus: [
        { value: null, label: 'All' },
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ],

    Status: [
        { value: null, label: 'All' },
        { value: true, label: 'Active' },
        { value: false, label: 'Inactive' },
    ],

    OrderType: [
        { value: null, label: 'All' },
        { value: 0, label: 'Home Delivery' },
        { value: 1, label: 'Store Location' },
    ],

    OrderStatusKey: {
        "All": null,
        "InCart": 0,
        "Placed": 1,
        "InProgress": 2,
        "Packed": 3,
        "OnTheWay": 4,
        "Completed": 5,
        "Cancelled": 6,
        "Refunded": 7,
        "CancelledandRefunded": 8,
        "DeliveredandRefunded": 9,
        "ChangeDeliveryDate": 10,
        "Edit": 11,
        "ReadyToCollect": 12,
        "PaymentPending": 13,
        "PaymentFailed": 14,
        "ConfirmOrder": 15,
        "CancelConfirmOrder": 16,
        "PendingConfirmation": 17,
    },

    OrderStatus: [
        { value: -1, label: 'Placed,In Process,Packed,OnTheWay,Ready to Collect,Pending Confimration', icon: 'fa fa-tasks' },
        { value: null, label: 'All', icon: 'fa fa-list' },
        { value: 0, label: "InCart", icon: 'fa fa-shopping-cart' },
        { value: 1, label: 'Placed', icon: 'fa fa-cart-plus' },
        { value: 2, label: 'In Process', icon: 'fa fa-laptop' },
        { value: 3, label: 'Packed', icon: 'fa fa-box' },
        { value: 4, label: 'OnTheWay', icon: 'fa fa-truck' },
        { value: 5, label: 'Completed', icon: 'fa fa-check-circle' },
        { value: 6, label: 'Cancelled', icon: 'fa fa-times-circle' },
        { value: 7, label: 'Refunded', icon: 'fa fa-undo' },
        { value: 8, label: 'Cancelled and Refunded', icon: 'fa fa-undo-alt' },
        { value: 9, label: 'Delivered and Refunded', icon: 'fa fa-box-open' },
        { value: 10, label: "Change Delivery Date", icon: 'fa fa-calendar-alt' },
        { value: 11, label: "Edit", icon: 'fa fa-edit' },
        { value: 12, label: 'Ready to Collect', icon: 'fa fa-bag-shopping' },
        { value: 13, label: "Payment Pending", icon: 'fa fa-exclamation-circle' },
        { value: 14, label: "Payment Failed", icon: 'fa fa-times' },
        { value: 15, label: "Confirm Order", icon: 'fa fa-check' },
        { value: 16, label: 'Cancel Confirm Order', icon: 'fa fa-ban' },
        { value: 17, label: 'Pending Confirmation', icon: 'fa fa-hourglass-half' }
    ],

    Months: [
        { value: null, label: 'All' },
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' },
    ],

    ExpiredStatus: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ],

    DisplayHomePage: [
        { value: null, label: 'All' },
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ],

    SellProduct: [
        { value: null, label: 'All' },
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ],

    DeviceType: [
        { value: null, label: 'All' },
        { value: 1, label: 'Web' },
        { value: 2, label: 'Android' },
        { value: 3, label: 'IOS' },
    ],
    SortOrder: {
        Ascending: 0,
        Descending: 1
    },
    MenuSortByList: {
        Unit: {
            Id: 0,
            name: 1,
        },
        Category: {
            Id: 0,
            name: 1,
        },
        Business: {
            Id: 0,
            name: 1,
        },
        Brand: {
            Id: 0,
            name: 1,
        },
        Tax: {
            Id: 0,
            value: 1,
        },
        SubCategory: {
            Id: 0,
            name: 1,
            categoryName: 2,
        },
        User: {
            Id: 0,
            firstName: 1,
            lastName: 2,
            userName: 3,
            roleName: 4,
        },
        Role: {  // here role means group
            Id: 0
        },
        PromotionCategory: {
            Id: 0,
            name: 1,
        },
        Products: {
            Id: 0,
            plu: 1,
            barcodeID: 2,
            productName: 3,
            brandName: 4,
            sellingPrice: 5,
            productSize: 6,
            taxValue: 7,
        },
        Holiday: {
            Id: 0,
            fromDate: 1,
            toDate: 2,
            active: 3
        },
        Customers: {
            firstName: 0,
            lastName: 1,
            mobileNo: 2,
            emailID: 3,
            RegistrationDate: 4,
            OrderCount: 5
        },
        Orders: {
            firstName: 0,
            totalAmount: 1,
            transactionDate: 2,
            deliveryOrCollectionDate: 3,
        }
    },

    ItemTypes: {
        Category: {
            Text: "Category",
            Value: 1
        },
        Product: {
            Text: "Product",
            Value: 2
        },
        PromotionCategory: {
            Text: "Promotion Category",
            Value: 3
        },
    },

    PromotionTypes: {
        Promocode: {
            Text: "Promocode",
            Value: 1
        },
        WasXNowX: {
            Text: "Was X.XX Now X.XX",
            Value: 2
        },
        ByNGetN: {
            Text: "By N Get N Free",
            Value: 3
        },
        ByNForXAmount: {
            Text: "By N For X.XX Amount",
            Value: 4
        },
    },

    WeekDays: {
        Sunday: {
            Text: "Sunday",
            Value: 1
        },
        Monday: {
            Text: "Monday",
            Value: 2
        },
        Tuesday: {
            Text: "Tuesday",
            Value: 3
        },
        Wednesday: {
            Text: "Wednesday",
            Value: 4
        },
        Thursday: {
            Text: "Thursday",
            Value: 5
        },
        Friday: {
            Text: "Friday",
            Value: 6
        },
        Saturday: {
            Text: "Saturday",
            Value: 7
        },
    },

    DiscountType: [
        { value: 1, label: 'Price' },
        { value: 2, label: 'Percentage' }
    ],

    DecimalInputSettings: {
        Default: 2,
        Latitude: 7
    },
    MaxLengthSettings: {
        Default: 8
    },
    FormMode: {
        Add: 1,
        Edit: 2,
        View: 3
    },
    GridOperation: {
        Add: 1,
        Update: 2,
        Delete: 3
    },
    PaymentMode: {
        CashOnDelivery: 1,
        Card: 2
    },
    DefaultPromotionColor: {
        BackgroundColor: "#ff0000",
        FontColor: "#ffffff"
    }
}

export const EnumsArr = {
    ItemTypes: [
        { value: Enums.ItemTypes.Category.Value, label: Enums.ItemTypes.Category.Text },
        { value: Enums.ItemTypes.Product.Value, label: Enums.ItemTypes.Product.Text },
        { value: Enums.ItemTypes.PromotionCategory.Value, label: Enums.ItemTypes.PromotionCategory.Text }
    ],
    PromotionTypes: [
        { value: Enums.PromotionTypes.Promocode.Value, label: Enums.PromotionTypes.Promocode.Text },
        { value: Enums.PromotionTypes.WasXNowX.Value, label: Enums.PromotionTypes.WasXNowX.Text },
        { value: Enums.PromotionTypes.ByNGetN.Value, label: Enums.PromotionTypes.ByNGetN.Text },
        { value: Enums.PromotionTypes.ByNForXAmount.Value, label: Enums.PromotionTypes.ByNForXAmount.Text }
    ],
    WeekdaysArr: [
        { value: Enums.WeekDays.Sunday.Value, label: Enums.WeekDays.Sunday.Text },
        { value: Enums.WeekDays.Monday.Value, label: Enums.WeekDays.Monday.Text },
        { value: Enums.WeekDays.Tuesday.Value, label: Enums.WeekDays.Tuesday.Text },
        { value: Enums.WeekDays.Wednesday.Value, label: Enums.WeekDays.Wednesday.Text },
        { value: Enums.WeekDays.Thursday.Value, label: Enums.WeekDays.Thursday.Text },
        { value: Enums.WeekDays.Friday.Value, label: Enums.WeekDays.Friday.Text },
        { value: Enums.WeekDays.Saturday.Value, label: Enums.WeekDays.Saturday.Text },
    ],
}

export const Menus = {

    dashboard: "Dashboard",
    Orders: "Orders",
    home: "home",
    Products: "Products",
    PromotionMainMenu: "Promotions",
    Promotions: "Promotions",
    Customers: "Customers",
    webContentsSettings: "webcontentssettings",
    settings: "settings",
    users: "users",
    UserManagement: "UserManagement",
    RoleManagement: "RoleManagement",
    Business: "Business",
    Tax: "Tax",
    HomeBanners: "HomeBanners",
    Announcements: "Announcements",
    PromotionCategory: "PromotionCategory",
    Departments: "Departments",
    SubDepartments: "SubDepartments",
    Brands: "Brands",
    Units: "Units",
    ShippingZones: "ShippingZones",
    Holiday: "Holiday",
    MainMenusettings: "MainMenusettings",
    pickingReport: "pickingreport",
    deliveryReport: "deliveryreport",
    collectionReport: "collectionreport",
    Reports: "Reports",
    SalesSummary: "SalesSummary",
    ProductSalesSummary: "ProductSalesSummary",
    ProductRefundSummary: "ProductRefundSummary",
    DeliveryCollection: "DeliveryandCollection",

}

export const productComponent = {
    filters: {
        BarcodeId: 1,
        ProductName: 2,
        Brand: 3,
        Category: 4,
        SubCategory: 5,
        homepage: 6
    },
    HomeBanner: 1,
    Promotions: 2,
    Menu: 3
};